import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import query from "./query";
import {
  filterSeoDataByLanguage,
  filterSeoDataByOtherLanguages,
} from "../../Utils/Language";

const Seo = (props) => {
  const data = query();
  const dataSeo = filterSeoDataByLanguage(
    props.page,
    props.locale,
    data.allContentfulSeo
  );

  const dataSeoOtherLang = filterSeoDataByOtherLanguages(
    props.page,
    props.locale,
    data.allContentfulSeo
  );

  const canonicalUrl =
    typeof window !== "undefined"
      ? window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname
      : "";
  const baseUrl =
    typeof window !== "undefined"
      ? window.location.protocol + "//" + window.location.host
      : "";

  const alternateLinks = dataSeoOtherLang.nodes.map((item) => (
    <link
      rel="alternate"
      hrefLang={
        item.node_locale === "it" ? "it_IT" : item.node_locale.replace("-", "_")
      }
      key={item.id}
      href={baseUrl + item.url}
    />
  ));

  return (
    <Helmet title={dataSeo.title} description={dataSeo.description.description}>
      <meta
        property="og:locale"
        content={props.locale === "it" ? "it_IT" : props.locale}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={dataSeo.title} />
      <meta
        property="og:description"
        content={dataSeo.description.description}
      />
      <meta property="og:url" content="https://hevelop.com/" />
      <meta property="og:site_name" content="Hevelop" />
      <meta
        property="og:image"
        content="https://images.ctfassets.net/5xqmr0m20ibb/6sFsKD5scfrIwuOFd9s2BY/b1d22b0e2d71afbbfe819d051ccae48f/insegna-hevelop_2_.jpg?h=250"
      />

      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:description"
        content={dataSeo.description.description}
      />
      <meta name="twitter:title" content={dataSeo.title} />
      <meta name="twitter:site" content="@hevelop" />
      <meta name="twitter:domain" content="Hevelop" />
      <meta name="twitter:creator" content="@hevelop" />

      <link rel="canonical" href={canonicalUrl} />

      {alternateLinks}

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "WebSite",
          url: "https://hevelop.com/",
          name: "Hevelop",
          alternateName: "Tecnologie per l'e-commerce",
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "@type",
          "http://schema.org": "Organization",
          url: "https://hevelop.com/",
          sameAs: [
            "https://www.facebook.com/hevelop",
            "https://www.linkedin.com/company/hevelop",
            "https://twitter.com/hevelop",
          ],
          name: "Hevelop srl",
          logo: "https://images.ctfassets.net/5xqmr0m20ibb/6sFsKD5scfrIwuOFd9s2BY/b1d22b0e2d71afbbfe819d051ccae48f/insegna-hevelop_2_.jpg?h=250",
        })}
      </script>
    </Helmet>
  );
};

Seo.propTypes = {
  locale: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};

export default Seo;
