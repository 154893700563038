import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulSeo {
        nodes {
          id
          node_locale
          page
          title
          description {
            description
          }
          url
        }
      }
    }
  `);
};

export default query;
