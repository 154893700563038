import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulPartnership {
        nodes {
          node_locale
          awsDescription {
            awsDescription
          }
          awsLogo {
            gatsbyImageData
            description
            file {
              url
            }
            title
          }
          awsTitle
          awsLink
          magentoDescription {
            magentoDescription
          }
          magentoLogos {
            gatsbyImageData
            file {
              url
            }
            title
          }
          magentoTitle
          mainImageSmall {
            gatsbyImageData
            file {
              url
            }
            title
          }
          mainImage {
            gatsbyImageData
            file {
              url
            }
            title
          }
          caFoscariLogo {
            gatsbyImageData
            file {
              url
            }
            title
          }
          caFoscariDescription {
            caFoscariDescription
          }
          caFoscariTitle
          caFoscariLink
          geminiLogo {
            gatsbyImageData
            file {
              url
            }
            title
          }
          geminiDescription {
            geminiDescription
          }
          geminiTitle
          geminiLink
          title
        }
      }
    }
  `);
};

export default query;
