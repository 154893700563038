import * as React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import "./Partnership.scss";
import query from "../PartnershipContent/query";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { filterDataByLanguage } from "../../Utils/Language";

const Partnership = (props) => {
  const data = query();
  const partnershipData = filterDataByLanguage(
    props.lang,
    data.allContentfulPartnership
  );

  return (
    <div className={`partnership-container ${props.className}`}>
      <Container fluid className={props.addTopBorder && "black-border-top"}>
        <Row>
          <Col className="col-12">
            <Nav className="flex-lg-row justify-content-between align-items-center flex-column col-12">
              <Nav.Item className="partnership-text col-12 col-lg-3 mb-3 mb-lg-0 text-lg-start text-start">
                Certifications and partnership
              </Nav.Item>
              <Nav.Item className="col-lg-2 col-12 mt-4 mt-lg-0 mb-5 mb-lg-0 text-lg-center justify-content-between align-items-center text-center d-flex logo-wrapper single">
                <Row className="d-flex justify-content-between align-items-center">
                  <Col
                    className="col-lg-4 col-3 ps-1 pe-1"
                    style={{ border: "0px solid red" }}
                  >
                    <GatsbyImage
                      className="logo-magento1"
                      image={
                        partnershipData &&
                        getImage(partnershipData.magentoLogos[2])
                      }
                      alt={
                        partnershipData && partnershipData.magentoLogos[0].title
                      }
                    />
                  </Col>
                  <Col
                    className="col-lg-4 col-4 ps-1 pe-1"
                    style={{ border: "0px solid red" }}
                  >
                    <GatsbyImage
                      className="logo-magento2"
                      image={
                        partnershipData &&
                        getImage(partnershipData.magentoLogos[1])
                      }
                      alt={
                        partnershipData && partnershipData.magentoLogos[1].title
                      }
                    />
                  </Col>
                  <Col
                    className="col-lg-4 col-4 ps-1 pe-1"
                    style={{ border: "0px solid red" }}
                  >
                    <GatsbyImage
                      className="logo-magento3"
                      image={
                        partnershipData &&
                        getImage(partnershipData.magentoLogos[0])
                      }
                      alt={
                        partnershipData && partnershipData.magentoLogos[1].title
                      }
                    />
                  </Col>
                </Row>
              </Nav.Item>
              <Nav.Item className="col-lg-2 col-12 mt-4 mt-lg-0 mb-5 mb-lg-0 text-lg-center text-start logo-wrapper single ps-1 pe-1">
                {partnershipData && partnershipData.geminiLink ? (
                  <a
                    href={partnershipData.geminiLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GatsbyImage
                      className="logo-aws"
                      image={
                        partnershipData && getImage(partnershipData.geminiLogo)
                      }
                      alt={
                        partnershipData &&
                        partnershipData.geminiLogo &&
                        partnershipData.geminiLogo.title
                      }
                    />
                  </a>
                ) : (
                  <GatsbyImage
                    className="logo-aws"
                    image={
                      partnershipData && getImage(partnershipData.geminiLogo)
                    }
                    alt={
                      partnershipData &&
                      partnershipData.geminiLogo &&
                      partnershipData.geminiLogo.title
                    }
                  />
                )}
              </Nav.Item>
              <Nav.Item className="col-lg-2 col-12 mt-4 mt-lg-0 mb-5 mb-lg-0 text-lg-center text-start logo-wrapper single ps-1 pe-1">
                {partnershipData && partnershipData.awsLink ? (
                  <a
                    href={partnershipData.awsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GatsbyImage
                      className="logo-aws"
                      image={
                        partnershipData && getImage(partnershipData.awsLogo)
                      }
                      alt={partnershipData && partnershipData.awsLogo.title}
                    />
                  </a>
                ) : (
                  <GatsbyImage
                    className="logo-aws"
                    image={partnershipData && getImage(partnershipData.awsLogo)}
                    alt={partnershipData && partnershipData.awsLogo.title}
                  />
                )}
              </Nav.Item>
              <Nav.Item className="col-lg-2 col-12 mt-4 mt-lg-0 mb-4 mb-lg-0 text-lg-start text-start logo-wrapper single ps-2 pe-2 ms-1 me-1">
                {partnershipData && partnershipData.caFoscariLink ? (
                  <a
                    href={partnershipData.caFoscariLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GatsbyImage
                      className="logo-cafoscari"
                      image={
                        partnershipData &&
                        getImage(partnershipData.caFoscariLogo)
                      }
                      alt={
                        partnershipData && partnershipData.caFoscariLogo.title
                      }
                    />
                  </a>
                ) : (
                  <GatsbyImage
                    className="logo-cafoscari"
                    image={
                      partnershipData && getImage(partnershipData.caFoscariLogo)
                    }
                    alt={partnershipData && partnershipData.caFoscariLogo.title}
                  />
                )}
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Partnership.propTypes = {
  className: PropTypes.string,
  addTopBorder: PropTypes.bool,
  lang: PropTypes.string.isRequired,
};

export default Partnership;
